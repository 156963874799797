import ImageGallery from 'react-image-gallery';

const images = [
  {
    original: '/picture/gallery/event_1.jpg',
    originalHeight: '500px'
  },
  {
    original: '/picture/gallery/event_2.jpg',
    originalHeight: '500px'
  },
  {
    original: '/picture/gallery/event_3.jpg',
    originalHeight: '500px'
  },
  {
    original: '/picture/gallery/event_4.jpg',
    originalHeight: '500px'
  },
  {
    original: '/picture/gallery/event_5.jpg',
    originalHeight: '500px'
  },
  {
    original: '/picture/gallery/event_6.jpg',
    originalHeight: '500px'
  }
];

export default function EventSection() {
  return (
    <section id="Imprezy">
      <div id="imprezy"></div>
      <div className="section events">
        <div className='sectionCol'>
          <div>
            <h1 class="tac">Imprezy zamkniete</h1>

            <p style={{marginBottom: "10px"}}><b>Zorganizuj z nami swoją imprezę urodzinową, firmową, okolicznościową!</b> </p>

            Twoją imprezę możemy zorganizować od <b>niedzieli do czwartku.</b> Wybierz swoje magiczne 6 godzin, w trakcie których odbędzie się impreza. Początek możliwy najwcześniej o godzinie 16, natomiast koniec o godzinie 3 w nocy.

            <p><b>Cena imprezy</b> zmienia się w zależności od ilości osób.</p>
            <ul style={{ marginLeft: "20px" }}>
              <li>Cena za wynajem <b>w wersji podstawowej wynosi 2600 zł</b> z limitem do 20 osób.</li>
              <li><b>Za każdą dodatkową osobę należy doliczyć 80 zł</b>, czyli np. dla 30 osób jest to 3400 zł</li>
              <li>Maksymalna ilość gości to 90</li>
            </ul>

            <p style={{ marginTop: "10px", marginBottom: "10px"}}>Jeżeli Twoja impreza miałaby odbyć się w piątek lub sobotę - cena jest ustalana indywidualnie.</p>

            <b>Możesz także zapewnić wyjątkową oprawę swojej imprezie.</b> Wybierz opcje dodatkowe, które ułatwią organizację i sprawią, że nie będziesz musiał/a się martwić o muzykę oraz obsługę:
            <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
              <li><em>DJa</em>, który poprowadzi imprezę, włącznie z karaoke (wedle życzenia) [cena 500 zł]</li>
              <li><em>Obsługę barową</em> - barmana, który serwować będzie drinki i napoje z karty Singers ze zniżką 30%! [cena 500 zł]</li>
              <li><em>Serwowanie dań</em> z karty Singers przez naszego kucharza - ze zniżką 20%! [cena 500 zł]</li>
              <li><em>Wyjątkowy Catering</em> wg zamówienia indywidualnego ustalanego minimum 3 dni robocze przed imprezą. Kwota minimalna zamówienia 1500 zł</li>
              <li><em>Przedłuż swoją imprezę</em> i świętowanie o dodatkową godzinę, maksymalnie do 3 w nocy [cena 500 zł]</li>
            </ul>
          </div>
          <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>Skontaktuj się z nami:</h3>
          <ul style={{ marginLeft: "20px" }}>
            <li>Napisz do nas maila pod adres <a class="eventLinks" href="mailto:singersrezerwacje@gmail.com"><b><u>singersrezerwacje@gmail.com</u></b></a> i opisz czego od nas oczekujesz, a my odpiszemy jak najszybciej to możliwe!</li>
            <li>Zadzwoń bezpośrednio do restauracji pod numer <a class="eventLinks" href="tel:535478687"><b><u>535 478 687</u></b></a></li>
          </ul>
        </div>
        <div className="sectionCol" style={{ alignItems: 'center' }}>
          <ImageGallery items={images} showNav={false} showBullets={true} autoPlay={true} slideDuration={500} />
        </div>
      </div>
    </section>
  )
}